import React, { useEffect, useState } from "react";
import "./completeKYC.scss";
import KYCVerificationLayout from "components/KYCverificaitonLayout";
import { IdentityCard, Passport } from "assets/icons";
import { BsArrowUpRight } from "react-icons/bs";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useKYCUploadInfo } from "utils/apis/auth.api";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router";
import Loader from "components/loader";
interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  country: string;
}

export default function CompleteKYC() {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  if(customerId){
    localStorage.setItem("customerId",customerId)
  }

  const schema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),

    email: yup.string().required("Email is required").email("Invalid email"),
    mobileNumber: yup.string().required("Phone no is required"),
  });
  const [nameAndFlag, setNameAndFlag] = useState({
    name: searchParams?.get('country'),
    flag: searchParams?.get('code')?.toLowerCase(),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues:{
      email:searchParams?.get('email') || '',
      mobileNumber:searchParams?.get('phone') || ''
    }
  });
  const [selectedDoc, setSelectedDoc] = useState("id-verification");
  const {
    mutate: KYCInfo,
    isLoading,
    isSuccess,
    data: res,
  } = useKYCUploadInfo();
  // const handleOnChange = (value: string, country: CountryData) => {
  //   setValue("mobileNumber", value);
  //   setNameAndFlag({
  //     name: country?.name,
  //     flag: country?.countryCode,
  //   });
  //   console.log('name and flag',nameAndFlag)
  // };
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const name = `${data.firstName} ${data.lastName}`;
    const { firstName, lastName, ...formValuesWithoutName } = data;
    console.log(name);
    (customerId && nameAndFlag?.name) &&
      KYCInfo({
        ...formValuesWithoutName,
        name,
        customerId,
        country: nameAndFlag?.name,
      });
    console.log(data);
  };
  useEffect(() => {
    if (isSuccess) {
      console.log("idid", res?.kyc?.id);
      navigate(`/${selectedDoc}`, {
        state: { id: res?.kyc?.id, customerId },
      });
    }
  }, [isLoading]);
  return (
    <KYCVerificationLayout>
      <div>
        <div className="comp-KYC">
          <div className="comp-KYC-form">
          <ul className="breadcrumb">
          <li>Verification</li>
 
  <li className="personal-verifi-li">Personal Verification</li>
</ul>
            <h1 className="fs--48 fw--700">Complete KYC</h1>
            <p className="fs--16 text">
              Please enter your relevant information
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                placeholder="First Name"
                {...register("firstName")}
              />
              {errors.firstName && (
                <span className="fs--10 error-msg">
                  {errors.firstName.message}
                </span>
              )}

              <input
                type="text"
                placeholder="Last Name"
                {...register("lastName")}
              />
              {errors.lastName && (
                <span className="fs--10 error-msg">
                  {errors.lastName.message}
                </span>
              )}

              <input readOnly type="email" placeholder="Email"  {...register("email")} />
              {errors.email && (
                <span className="fs--10 error-msg ">
                  {errors.email.message}
                </span>
              )}
              {/* <PhoneInput
              disabled
                placeholder="+1 349 53433"
                value={searchParams?.get('phone')}
                inputProps={{
                  name: "mobileNumber",
                  autoFocus: true,
                }}
                inputStyle={{
                  width: "90%",
                  border: "none",
                  marginTop: "20px",
                  borderBottom: "1px solid #989898",
                  borderRadius: "0",
                  backgroundColor: "transparent",
                }}
                buttonStyle={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                country="gb"
                onChange={(e, country: CountryData) =>
                  handleOnChange(e, country)
                }
              /> */}
             
             <div className="flagAndName" >
                <img
                  src={`https://flagcdn.com/w320/${nameAndFlag?.flag}.png`}
                  alt="flag"
                />
                <p>{searchParams?.get('phone')}</p>
              </div>
              {/* {errors.mobileNumber && (
                <span className="fs--10 error-msg">
                  {errors.mobileNumber.message}
                </span>
              )} */}

              <div className="flagAndName">
                <img
                  src={`https://flagcdn.com/w320/${nameAndFlag?.flag}.png`}
                  alt="flag"
                />
                <p>{nameAndFlag?.name}</p>
              </div>
              {/* <input type="submit" /> */}
            </form>
          </div>
          <div className="gov-issued-doc">
            <h4 className="fs--25 fw--600">
              Use a valid government-issued-document
            </h4>
            {/* <p className="fs--16 text">
              Please check your provided phone number and enter the OTP code
              here to complete your profile sign up.
            </p> */}
            <div className="docs-verification-link">
              <div
                onClick={() => setSelectedDoc("id-verification")}
                className={`ID-card ${
                  selectedDoc === "id-verification" ? "selected-doc-border" : ""
                }`}
              >
                <IdentityCard />
                <h5 className="fs--24 fs--700">Identity Card</h5>
                <div className="docs-btn">
                  <BsArrowUpRight />
                </div>
              </div>
              <div
                onClick={() => setSelectedDoc("passport-verification")}
                className={`passport ${
                  selectedDoc === "passport-verification"
                    ? "selected-doc-border"
                    : ""
                }`}
              >
                <Passport />
                <h5 className="fs--24 fs--700">Passport</h5>
                <div className="docs-btn">
                  <BsArrowUpRight />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="next-btn-box">
          <button className="next-btn"
           onClick={handleSubmit(onSubmit)}
           >
            {isLoading ? <Loader/> : "Next"}
          </button>
        </div>
      </div>
    </KYCVerificationLayout>
  );
}
