import KYCVerificationLayout from "components/KYCverificaitonLayout";
import "./IDVerificatin.scss";
import { ImageUploaderIcon } from "assets/icons";
import { useLocation, useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useKYCIDImage, useMediaUpload } from "utils/apis/auth.api";
import Loader from "components/loader";
export default function IDVerification() {
  const navigate = useNavigate();
  const location = useLocation();

  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const [inputRef2, setInputRef2] = React.useState<HTMLInputElement | null>(
    null
  );
  const [idNumber, setIdNumber] = React.useState("");
  const [file, setFile] = React.useState<File[]>([]);
  const [showInputError,setShowInputError] = useState(false)
  const {
    mutate: uploadID,
    isSuccess: mediaUpload,
    data: imageRes,
    isLoading:uploadID1
  } = useMediaUpload();
  const {
    mutate: uploadID2,
    isSuccess: mediaUpload2,
    isLoading:uploadID22,
    data: imageRes2,
  } = useMediaUpload();
  const handleClick = () => {
    if (inputRef) {
      inputRef.click();
    }
  };
  const handleClick2 = () => {
    if (inputRef2) {
      inputRef2.click();
    }
  };
  const handleChange = async (files: FileList | null) => {
    if (files) {
      const image = files[0];
      setFile([...file, image]);
    }
  };
  const {
    mutate: KYCIdentityImage,
    isLoading:kycIdLoader,
    isSuccess,
    data: res,
  } = useKYCIDImage();

  useEffect(() => {
    console.log("resImages useEffect", imageRes?.url);
    if (mediaUpload) {
      console.log("resImages", imageRes2?.url, imageRes?.url);
      if (mediaUpload && mediaUpload2)
   idNumber ?     KYCIdentityImage({
          frontURL: imageRes?.url,
          backURL: imageRes2?.url,
          documentNumber: idNumber,
          kycId: location?.state?.id,
          documentType: "identity",
        }):setShowInputError(true)
    }
  }, [mediaUpload,mediaUpload2]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/image-verification", {
        state: { id: res?.kyc?.id, back: "/id-verification" },
      });
    }
  }, [kycIdLoader]);

  const handleSubmitId = () => {
    uploadID(file[0])
    uploadID2(file[1])
  };

  return (
    <KYCVerificationLayout>
      <div>
        <div className="">
          <ul className="breadcrumb">
            <li>Verification</li>
            <li>Personal Verification</li>
            <li className="id-verifi-li">ID Verification</li>
          </ul>
          <h1 className="fs--48 fw--700">ID Card Verification</h1>
          <p className="fs--16 text">Please enter your relevant information</p>
          <input
            placeholder="ID card number"
            type="text"
            className="id-card-card"
            value={idNumber}
            onChange={(e) => setIdNumber(e.target.value)}
          />
          {showInputError && <span className="error-msg fs--12">Enter ID card no. please</span>}
          <div className="id-image">
            <div className="front-side">
              <h3>Front Side</h3>
              <div
                className="image-uploader-box"
                onDrop={(e) => {
                  e.preventDefault();
                  handleChange(e.dataTransfer?.files);
                }}
                onDragOver={(e) => e.preventDefault()}
                onClick={handleClick}
              >
                {file.length !== 0 ? (
                  <img
                    src={URL.createObjectURL(file[0])}
                    alt="Preview"
                    className="uploaded-image"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <div className="image-uploader-icon">
                    <ImageUploaderIcon />
                    <br />
                    <br />
                    <p className="fs--13 text">
                      Browse or Drag and Drop an image here
                    </p>
                    <input
                      type="file"
                      // border="0"
                      className="input-for-upload-docs"
                      ref={(input1) => setInputRef(input1)}
                      onChange={(e) => handleChange(e.target.files)}
                      accept="image/png, image/jpeg"
                      // display="none"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="back-side">
              <h3>Back Side</h3>
              <div
                className="image-uploader-box"
                onDrop={(e) => {
                  e.preventDefault();
                  handleChange(e.dataTransfer?.files);
                }}
                onDragOver={(e) => e.preventDefault()}
                onClick={handleClick2}
              >
                {file[1] ? (
                  <img
                    src={URL.createObjectURL(file[1])}
                    alt="Preview"
                    className="uploaded-image"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <div className="image-uploader-icon">
                    <ImageUploaderIcon />
                    <br />
                    <br />
                    <p className="fs--13 text">
                      Browse or Drag and Drop an image here
                    </p>
                    <input
                      type="file"
                      // border="0"
                      className="input-for-upload-docs"
                      ref={(input2) => setInputRef2(input2)}
                      onChange={(e) => handleChange(e.target.files)}
                      accept="image/png, image/jpeg"
                      // display="none"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="fs--13 text allow-file">Upload JPEG, PNG Files only</p>
        </div>
        <div className="next-btn-box">
          {/* <button
            className="back-btn"
            onClick={() => navigate("/verification")}
          >
            Back
          </button> */}
          <button className="next-btn" onClick={() => handleSubmitId()}>
            {kycIdLoader || uploadID22 || uploadID1 ? <Loader/> : "Next"}
          </button>
        </div>
      </div>
    </KYCVerificationLayout>
  );
}
