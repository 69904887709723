import { QueryCache } from '@tanstack/react-query'
// import * as auth from 'auth-provider'
const apiURL = 'https://api.kycprove.com'
const queryCache = new QueryCache({
  onError: error => {
    console.log(error)
  },
  onSuccess: data => {
    console.log(data)
  },
})

async function client(
  endpoint: string,
  {
    data,
    headers: customHeaders,
    ...customConfig
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Partial<RequestInit> & { data?: any } = {},
) {
  const isFormData = data instanceof FormData
  const token = localStorage.getItem('access_token')

  const config: Partial<RequestInit> = {
    method: data ? 'POST' : 'GET',
    body: data ? (isFormData ? data : JSON.stringify(data)) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      ...(data && !isFormData
        ? {
          'Content-Type': 'application/json',
        }
        : {}),
      ...customHeaders,
    },
    ...customConfig,
  }

  return window.fetch(`${apiURL}/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      queryCache.clear()
      // await auth.logout()
      // refresh the page for them
      // window.location.assign(window.location.toString())
      return Promise.reject({ message: 'Please re-authenticate.' })
    }
    const data = await response.json()

    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export { client }
