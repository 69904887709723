import React, { useState } from "react";
import KYCVerificationLayout from "components/KYCverificaitonLayout";
import imageVerification from "assets/images/imageVerifi.png";
import { ImageUploaderIcon } from "assets/icons";
import "./imageVerification.scss";
import { useKYCImage, useMediaUpload } from "utils/apis/auth.api";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import Loader from "components/loader";
export default function ImageVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File[]>([]);

  const handleClick = () => {
    if (inputRef) {
      inputRef.click();
    }
  };
  const handleChange = async (files: FileList | null) => {
    if (files) {
      const image = files[0];
      setFile([image]);
    }
  };
  const { mutate: KYCImage, isLoading, isSuccess, data: res } = useKYCImage();
  const {
    mutate: uploadID,
    isSuccess: mediaUpload,
    isLoading:uploadIDLoader,
    data: imageRes,
  } = useMediaUpload();
  useEffect(() => {
    console.log("resImages useEffect", imageRes?.url);
    if (mediaUpload) {
      console.log("resImages", imageRes?.url, imageRes?.url);
      if (mediaUpload && mediaUpload)
      KYCImage({
        imageURL: imageRes?.url,
        kycId: location?.state?.id,
      })
    }
  }, [mediaUpload]);
  useEffect(() => {
    if (isSuccess) {
      navigate("/upload-complete");
    }
  }, [isLoading]);
  
  const handleSubmitId = () => {
    uploadID(file[0])
  };
  return (
    <KYCVerificationLayout>
      <>
      <ul className="breadcrumb">
          <li>Verification</li>
          <li>Personal Verification</li>
  <li>ID Verification</li>
  <li className="image-verifi-li">image verification</li>
</ul>
<div className="img-verifi-mobile-header"> <h1 className="fs--48 fw--700">Image Verification</h1>
            <p className="fs--16 text">
              Please enter your relevant information
            </p></div>
        <div className="img-verfi">
          <div className="img-verifi-uploader">
           <div className="img-verifi-desktop-header"> <h1 className="fs--48 fw--700">Image Verification</h1>
            <p className="fs--16 text">
              Please enter your relevant information
            </p></div>
            <h3>Upload an Image</h3>
            <div
              className="image-uploader-box"
              onDrop={(e) => {
                e.preventDefault();
                handleChange(e.dataTransfer?.files);
              }}
              onDragOver={(e) => e.preventDefault()}
              onClick={handleClick}
            >
              {file.length !== 0 ? (
                <img
                  src={ URL.createObjectURL(file[0])}
                  alt="Preview"
                  className="uploaded-image"
                  width="100%"
                  height="100%"
                />
              ) : (
                <div className="image-uploader-icon">
                  <ImageUploaderIcon />
                  <br />
                  <br />
                  <p className="fs--13 text">
                    Browse or Drag and Drop an image here
                  </p>
                  <input
                    type="file"
                    // border="0"
                    className="input-for-upload-docs"
                    ref={(input1) => setInputRef(input1)}
                    onChange={(e) => handleChange(e.target.files)}
                    accept="image/png, image/jpeg"
                    // display="none"
                  />
                </div>
              )}
            </div>
            <p className="fs--13 text allow-file">
              Upload JPEG, PNG Files only
            </p>
          </div>
          <div className="iimage">
            <img src={imageVerification} alt="image" />
          </div>
        </div>
        <div className="next-btn-box">
          {/* <button
            className="back-btn"
            onClick={() => navigate(state?.back)}
          >
            Back
          </button> */}
          <button
            className="next-btn"
            onClick={() =>
              handleSubmitId()
            }
          >
            {isLoading || uploadIDLoader?<Loader/> : "Next"}
          </button>
        </div>
      </>
    </KYCVerificationLayout>
  );
}
