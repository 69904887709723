import React, { useState } from "react";
import { ImageUploaderIcon, PassportImage } from "assets/icons";
import KYCVerificationLayout from "components/KYCverificaitonLayout";
import "./passportVerifi.scss";
import { useEffect } from "react";
import { useKYCIDImage, useMediaUpload } from "utils/apis/auth.api";
import { useLocation, useNavigate } from "react-router";
import Loader from "components/loader";
export default function PassportVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File[]>([]);
  const [idNumber, setIdNumber] = React.useState("");
  const [showInputError,setShowInputError] = useState(false)

  const handleClick = () => {
    if (inputRef) {
      inputRef.click();
    }
  };
  const handleChange = async (files: FileList | null) => {
    if (files) {
      const image = files[0];
      setFile([image]);
    }
  };
  const { mutate: KYCImage, isLoading, isSuccess, data: res } = useKYCIDImage();
  const {
    mutate: uploadID,
    isSuccess: mediaUpload,
    isLoading:uploadID1,
    data: imageRes,
  } = useMediaUpload();
  useEffect(() => {
    console.log("resImages useEffect", imageRes?.url);
    if (mediaUpload) {
      console.log("resImages", imageRes?.url, imageRes?.url);
      if (mediaUpload && mediaUpload)
    idNumber ?  KYCImage({
        // imageURL: imageRes?.url,
        frontURL: imageRes?.url,
        backURL: "",
        documentNumber: idNumber,
        kycId: location?.state?.id,
        documentType: "passport",
      }):setShowInputError(true)
    }
  }, [mediaUpload]);
  useEffect(() => {
    if (isSuccess) {
      navigate("/image-verification",{state:{back:'/passport-verification',id: res?.kyc?.id,}});
    }
  }, [isLoading]);
  const handleSubmitId = () => {
    uploadID(file[0])
  };
  return (
    <KYCVerificationLayout>
      <>
      <ul className="breadcrumb">
          <li>Verification</li>
          <li>Personal Verification</li>
  <li className="pass-verifi-li">Passport Verification</li>
</ul>
      <div className="heading-for-mobile-view"> <h1 className="fs--48 fw--700">Passport Verification</h1>
            <p className="fs--16 text">
              Please enter your relevant information
            </p></div>
        <div className="passport-info">
          <div className="pass-uploader">
            <div className="heading-for-desktop-view">
            <h1 className="fs--48 fw--700">Passport Verification</h1>
            <p className="fs--16 text">
              Please enter your relevant information
            </p></div>
            <input placeholder="Passport number" type="text"   onChange={(e) => setIdNumber(e.target.value)}/>
            {showInputError && <span className="error-msg fs--12">Enter passport no. please</span>}
            <h3>passport Image</h3>
            <div
              className="image-uploader-box"
              onDrop={(e) => {
                e.preventDefault();
                handleChange(e.dataTransfer?.files);
              }}
              onDragOver={(e) => e.preventDefault()}
              onClick={handleClick}
            >
              {file.length !== 0 ? (
                <img
                src={ URL.createObjectURL(file[0])}
                  alt="Preview"
                  className="uploaded-image"
                  width="100%"
                  height="100%"
                />
              ) : (
                <div className="image-uploader-icon">
                  <ImageUploaderIcon />
                  <br />
                  <br />
                  <p className="fs--13 text">
                    Browse or Drag and Drop an image here
                  </p>
                  <input
                    type="file"
                    className="input-for-upload-docs"
                    ref={(input1) => setInputRef(input1)}
                    onChange={(e) => handleChange(e.target.files)}
                    accept="image/png, image/jpeg"
                  />
                </div>
              )}
            </div>
            <p className="fs--13 text allow-file">
              Upload JPEG, PNG Files only
            </p>
          </div>
          <div className="pass-img">
            <PassportImage />
          </div>
        </div>
        <div className="next-btn-box">
          {/* <button
            className="back-btn"
            onClick={() => navigate("/verification")}
          >
            Back
          </button> */}
          <button
            className="next-btn"
            onClick={() =>

                handleSubmitId()
              
            }
          >
            {isLoading || uploadID1 ? <Loader/> : "Next"}
          </button>
        </div>
      </>
    </KYCVerificationLayout>
  );
}
