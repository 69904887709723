import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "views/about";
import IDVerification from "views/verification/IDVerification";
import CompleteKYC from "views/verification/completeKYC";
import ImageVerification from "views/verification/imageVerification";
import PassportVerification from "views/verification/passportVerification";
import UploadComplete from "views/verification/uploadComplete";

const routes = [
  // {
  //   path: "/",
  //   Component: About,
  // },
  {
    path: `/verification/:customerId`,
    Component: CompleteKYC,
  },
  {
    path: "/id-verification",
    Component: IDVerification,
  },
  {
    path: "/passport-verification",
    Component: PassportVerification,
  },
  {
    path: "/image-verification",
    Component: ImageVerification,
  },
  {
    path: "/upload-complete",
    Component: UploadComplete,
  },
];
export default function AppRoutes() {
  return (
    <Routes>
      {routes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
