import React from "react";
import "./KYCverifiLayout.scss";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoDesign } from "assets/icons";
import { useRedirectURL } from "utils/apis/auth.api";
// import logo from 'assets/icons/logoDesign.svg'
export default function KYCVerificationLayout({
  children,
}: {
  children: JSX.Element;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const {data:backtohome} = useRedirectURL()
  return (
    <div>
      {/* <div className="offer fs--12 fw--500">
        <div className="container">
          KYC Prove is offering free Monthly Trail. Click Here!
        </div>
      </div> */}
      <div className="logo">
        <div className="container">
          <LogoDesign width='220' />
          {/* <img src={logo}/> */}
        </div>
      </div>

      <div className="container">
        {children}

        {location.pathname === "/upload-complete" && (
          <div className="upload-complete-btn-box">
            <button
              className="upload-complete-btn"
              onClick={() =>{backtohome && window.location.assign(backtohome?.redirectUrl)}}
            >
              Go to Home
            </button>
          </div>
        )}
      </div>

      <div className="container">
        <div className="kyc-footer">
          <div className="social-icons">
            <div className="icons">
              <BsTwitter className="react-icons" />
            </div>
            <div className="icons">
              <BsFacebook className="react-icons" />
            </div>
            <div className="icons">
              <BsInstagram className="react-icons" />
            </div>
            {/* <div className="icons">
              <BsGithub className="react-icons" />
            </div> */}
          </div>
          <p className="">
            © Copyright {new Date().getFullYear()} , All Rights Reserved by
            KYC Prove
          </p>
        </div>
      </div>
    </div>
  );
}
