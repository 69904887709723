import { useMutation, useQuery } from "@tanstack/react-query";
import { useCustomserId } from "utils/hooks";
import {
  AuthFormValues,
  IKYC,
  IRedirectURL,
} from "utils/types/global.types";
import { client } from "./api-client";
export const useKYCUploadInfo = () => {
  const id = useCustomserId();
  return useMutation<{ kyc: IKYC }, Error, AuthFormValues>((data) =>
    client("kyc/uploadInfo", {
      data,
      headers: { "X-API-KEY": id },
    })
  );
};

export const useKYCIDImage = () => {
  const id = useCustomserId();
  return useMutation<{ kyc: IKYC }, Error, unknown>((data) =>
    client("kyc/uploadDocument", {
      data,
      headers: { "X-API-KEY": id },
    })
  );
};
export const useKYCImage = () => {
  const id = useCustomserId();
  return useMutation<unknown, Error, unknown>((data) =>
    client("kyc/uploadImage", {
      data,
      headers: { "X-API-KEY": id },
    })
  );
};

export const useMediaUpload = () =>
  useMutation<{ url: string }, Error, File>((data) => {
    const formData = new FormData();
    formData.append("file", data);
    return client(`media-upload/mediaFiles/test`, {
      data: formData,
    });
  });


export const useRedirectURL = () => {
  const id = useCustomserId();
  return useQuery<IRedirectURL, Error>(["geturl", { id }], () => {
    return client(`customer/getCustomerById/${id}`);
  });
};
