import { UploadedIcon } from "assets/icons";
import KYCVerificationLayout from "components/KYCverificaitonLayout";
import "./uploadComp.scss";
export default function UploadComplete() {
  return (
    <KYCVerificationLayout>
      <div className="upload-comp">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <UploadedIcon />
        <br />
        <br />
        <h3>Documents Uploaded</h3>
        <br />
        <p className="upload-doc-msg">
          You have succesfully uploaded required information, the verification
          process can take up-to 2 working days. We will let you know once we’ve
          verified everything.
        </p>
      </div>
    </KYCVerificationLayout>
  );
}
